import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

const mapStateToProps = ({ user: { loggedIn } }) => ({ loggedIn });

const dontRequireAuth = (ComposedComponent) => {
    const notAuth = ({ loggedIn, ...props }) => {
        if (loggedIn) {
            return <Redirect to="/home" />;
        }

        return (
            <React.Fragment>
                <ComposedComponent {...props} />
            </React.Fragment>
        );
    };

    notAuth.propTypes = {
        loggedIn: PropTypes.bool.isRequired,
    };

    return connect(mapStateToProps)(notAuth);
};

export default dontRequireAuth;
