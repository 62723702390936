export const SET_AUTHORIZED_SERVICES = 'SET_AUTHORIZED_SERVICES';
export const SET_ALL_SERVICES = 'SET_SERVICES';

const setAuthorizedServices = (authorizedServices) => ({
    type: SET_AUTHORIZED_SERVICES,
    authorizedServices,
});

const setAllServices = (allServices) => ({
    type: SET_ALL_SERVICES,
    allServices,
});

export const getAuthorizedServices = () => (dispatch, getState) => {
    const headers = {
        'Content-type': 'application/json',
        "x-access-token": sessionStorage.getItem("JWT")
    };
    const options = { headers, method: 'GET' };

    const { user: { id } } = getState();

    fetch(`${process.env.REACT_APP_API_URL}/services/authorized/${id}`, options)
        .then((response) => {
            if (response.ok) {
                return response.json();
            }

            return response.error;
        })
        .then((data) => {
            if (data) {
                dispatch(setAuthorizedServices(data));
            }
        });
};

export const getAllServices = () => (dispatch, getState) => {
    const headers = {
        'Content-type': 'application/json',
        "x-access-token": sessionStorage.getItem("JWT")
    };
    const options = { headers, method: 'GET' };

    const { services: { allServices } } = getState();

    if (!allServices.length) {
        fetch(`${process.env.REACT_APP_API_URL}/services/`, options)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                return response.error;
            })
            .then((data) => {
                if (data) {
                    dispatch(setAllServices(data));
                }
            });
    }
};
