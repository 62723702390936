import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl, intlShape } from 'react-intl';
import { Table, Input, Button, Icon } from 'antd';
import Highlighter from 'react-highlight-words';

import PageLayout from '../Layout/PageLayout';
import EditUserModal from '../components/EditUserModal';

import { getUsers as getUsersAction } from '../actions/users';

class Users extends Component {
    constructor(props) {
        super(props);

        this.getColumnSearchProps = this.getColumnSearchProps.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.onRow = this.onRow.bind(this);

        this.editUserModal = React.createRef();

        this.state = {
            searchText: '',
        };

        const { intl } = props;

        this.columns = [{
            title: intl.formatMessage({ id: 'users.table.firstName' }),
            dataIndex: 'firstName',
            ...this.getColumnSearchProps('firstName'),
        }, {
            title: intl.formatMessage({ id: 'users.table.lastName' }),
            dataIndex: 'lastName',
            ...this.getColumnSearchProps('lastName'),
        }, {
            title: intl.formatMessage({ id: 'users.table.email' }),
            dataIndex: 'email',
            ...this.getColumnSearchProps('email'),
        }, {
            title: intl.formatMessage({ id: 'users.table.callNumber' }),
            dataIndex: 'callNumber',
            sorter: (a, b) => a.callNumber - b.callNumber,
        }, {
            title: intl.formatMessage({ id: 'users.table.servicesNumber' }),
            dataIndex: 'servicesNumber',
            sorter: (a, b) => a.servicesNumber - b.servicesNumber,
        }];
    }

    componentDidMount() {
        const { getUsers } = this.props;

        document.title = 'ROOTE Dashboard';

        getUsers();
    }

    onRow(record) {
        return {
            onClick: () => this.handleRowClick(record),
        };
    }

    getColumnSearchProps(dataIndex) {
        const { intl } = this.props;

        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={(node) => { this.searchInput = node; }}
                        placeholder={`${intl.formatMessage({ id: 'users.table.search' })}`
                        + ` ${intl.formatMessage({ id: `users.table.${dataIndex}` })}`}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                        style={{ width: 268, marginBottom: 8, display: 'block' }}
                    />
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm)}
                        icon="search"
                        size="small"
                        style={{ width: 130, marginRight: 8 }}
                    >
                        {intl.formatMessage({ id: 'users.table.search' })}
                    </Button>
                    <Button
                        onClick={() => this.handleReset(clearFilters)}
                        size="small"
                        style={{ width: 130 }}
                    >
                        {intl.formatMessage({ id: 'users.table.reset' })}
                    </Button>
                </div>
            ),
            filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
            onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
                if (visible) {
                    setTimeout(() => this.searchInput.select());
                }
            },
            render: (text) => {
                const { searchText } = this.state;

                return (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text.toString()}
                    />
                );
            },
        });
    }

    handleRowClick(record) {
        this.editUserModal.current.show(record);
    }

    handleSearch(selectedKeys, confirm) {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
    }

    handleReset(clearFilters) {
        clearFilters();
        this.setState({ searchText: '' });
    }

    render() {
        const { users } = this.props;

        return (
            <PageLayout pageKey="users">
                <EditUserModal ref={this.editUserModal} />
                <Table
                    dataSource={users}
                    columns={this.columns}
                    rowKey="id"
                    onRow={this.onRow}
                />
            </PageLayout>
        );
    }
}

Users.propTypes = {
    intl: intlShape.isRequired,
    getUsers: PropTypes.func.isRequired,
    users: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        role: PropTypes.string.isRequired,
        callNumber: PropTypes.number.isRequired,
        servicesNumber: PropTypes.number.isRequired,
        credits: PropTypes.number.isRequired,
        isActive: PropTypes.bool.isRequired,
    })).isRequired,
};

const mapStateToProps = ({ users: { list } }) => ({ users: list });
const mapDispatchToProps = { getUsers: getUsersAction };

export default compose(
    injectIntl,
    connect(mapStateToProps, mapDispatchToProps),
)(Users);
