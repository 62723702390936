import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert } from 'antd';
import { FormattedMessage } from 'react-intl';
import LoginCard from '../components/LoginCard';
import RegisterCard from '../components/RegisterCard';

import logo from '../assets/ROOTE_white.svg';

import './Login.css';

class Login extends PureComponent {
    componentDidMount() {
        document.title = 'Login ROOTE API';
    }

    alertMessage() {
        const { message } = this.props;

        let type;
        let alertMessage;

        if (!message) {
            return null;
        }

        if (!message.error) {
            type = 'success';

            if ('ACCOUNT_CREATED' === message.type) {
                alertMessage = (
                    <FormattedMessage
                        id="login.registration.success"
                    />
                );
            } else {
                return null;
            }
        } else {
            type = 'error';

            if ('CANT_CREATE_ACCOUNT' === message.type) {
                alertMessage = (
                    <FormattedMessage
                        id="login.registration.fail"
                    />
                );
            } else if ('CANT_LOGIN' === message.type) {
                alertMessage = (
                    <FormattedMessage
                        id="login.failed"
                    />
                );
            } else {
                return null;
            }
        }

        return (
            <div className="alerts-container">
                <Alert
                    type={type}
                    message={alertMessage}
                />
            </div>
        );
    }

    render() {
        const { history: { push } } = this.props;

        return (
            <div className="sign-in-container">
                <div className="logo-container">
                    <img className="logo" alt="logo" src={logo} />
                </div>
                {this.alertMessage()}
                <div className="sign-in-cards-container">
                    <RegisterCard />
                    <div className="divider" />
                    <LoginCard push={push} />
                </div>
                <div className="links-container">
                    <a
                        className="link"
                        href="https://roote.fr/pdf/CGU.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        CGU
                    </a>
                    <a
                        className="link"
                        href="https://roote.fr/pdf/privacy.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        PRIVACY
                    </a>
                </div>
            </div>
        );
    }
}

Login.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    message: PropTypes.shape({
        error: PropTypes.bool.isRequired,
        type: PropTypes.string.isRequired,
    }),
};

Login.defaultProps = {
    message: null,
};

const mapStateToProps = ({ user: { message } }) => ({ message });
export default connect(mapStateToProps)(Login);
