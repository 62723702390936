import { createBrowserHistory } from 'history';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ReactGA from 'react-ga';
import { connect, Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { IntlProvider } from 'react-intl';
import { StripeProvider } from 'react-stripe-elements';

import { store, persistor } from './createStore';
import Routes from './Routes';
import translations from './translations';

import './App.css';

const history = createBrowserHistory();

const IntlApp = ({ locale }) => (
    <IntlProvider
        locale={locale}
        messages={translations[locale]}
    >
        <Router history={history}>
            <Routes />
        </Router>
    </IntlProvider>
);

IntlApp.propTypes = {
    locale: PropTypes.string.isRequired,
};

const mapStateToProps = ({ app: { locale } }) => ({ locale });

const AppInner = connect(mapStateToProps)(IntlApp);

const initGoogleAnalytics = (gaTrackingId) => {
    ReactGA.initialize(gaTrackingId);
    ReactGA.pageview(`${history.location.pathname}${history.location.hash}`);
};

class App extends PureComponent {
    constructor(props) {
        super(props);
        this.history = createBrowserHistory();
        this.store = store;

        history.listen((location) => {
            ReactGA.pageview(`${location.pathname}${location.hash}`);
        });

        this.state = {
            stripeApiKey: null,
        };
    }

    componentDidMount() {
        const headers = {
            'Content-type': 'application/json',
        };
        const options = { headers, method: 'GET' };

        fetch(`${process.env.REACT_APP_API_URL}/env`, options)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                return response.error;
            })
            .then((data) => {
                if (data) {
                    const { stripeApiKey, gaTrackingId } = data;

                    initGoogleAnalytics(gaTrackingId);

                    this.setState({
                        stripeApiKey,
                    });
                }
            });
    }

    render() {
        const { stripeApiKey } = this.state;

        if (!stripeApiKey) {
            return null;
        }

        return (
            <Provider store={this.store}>
                <PersistGate loading={null} persistor={persistor}>
                    <StripeProvider apiKey={stripeApiKey}>
                        <AppInner />
                    </StripeProvider>
                </PersistGate>
            </Provider>
        );
    }
}

export default App;
