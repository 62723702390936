import { SET_USERS } from '../actions/users';

const defaultState = {
    list: [],
};

const usersReducer = (state = defaultState, { type, ...payload }) => {
    switch (type) {
        case SET_USERS:
            return { ...state, list: payload.users };
        default:
            return state;
    }
};

export default usersReducer;
