import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { CardExpiryElement, CardCVCElement, CardNumberElement, injectStripe } from 'react-stripe-elements';

import './CheckoutForm.css';

class CheckoutForm extends Component {
    constructor(props) {
        super(props);

        this.submit = this.submit.bind(this);

        this.state = {
            loading: false,
        };
    }

    async submit() {
        const { stripe, firstName, lastName, pack, onPaymentSuccess, onPaymentError } = this.props;

        this.setState({
            loading: true,
        });

        const { token } = await stripe.createToken({ name: `${firstName} ${lastName}` });

        if (token) {
            const body = JSON.stringify({ source: token.id, packId: pack.id });
            const response = await fetch(`${process.env.REACT_APP_API_URL}/charge`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', "x-access-token": sessionStorage.getItem("JWT") },
                body,
            });

            if (response.ok) {
                onPaymentSuccess(pack);
            } else {
                onPaymentError(pack);
            }
        } else {
            onPaymentError(pack);
        }

        this.setState({
            loading: false,
        });
    }

    render() {
        const { loading } = this.state;
        const { pack } = this.props;

        return (
            <div className="checkout">
                <h3 className="title">
                    <FormattedMessage id="user.pay" />
                </h3>
                <p className="desc">
                    <FormattedMessage id="user.checkoutDesc" values={{ credits: pack.credits }} />
                </p>
                <form>
                    <div className="card-element-container card-number">
                        <CardNumberElement />
                    </div>
                    <div className="bottom-card-elements">
                        <div className="card-element-container card-expiry">
                            <CardExpiryElement />
                        </div>
                        <div className="card-element-container card-cvc">
                            <CardCVCElement />
                        </div>
                    </div>
                </form>
                <Button className="checkout-button" type="primary" loading={loading} onClick={this.submit}>
                    <FormattedMessage id="user.button.pay" />
                </Button>
            </div>
        );
    }
}

CheckoutForm.propTypes = {
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    stripe: PropTypes.shape({
        createToken: PropTypes.func.isRequired,
    }).isRequired,
    pack: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        credits: PropTypes.number.isRequired,
    }).isRequired,
    onPaymentSuccess: PropTypes.func.isRequired,
    onPaymentError: PropTypes.func.isRequired,
};

const mapStateToProps = ({ user: { firstName, lastName } }) => ({ firstName, lastName });

export default compose(
    connect(mapStateToProps),
    injectStripe,
)(CheckoutForm);
