import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Card, Form, Icon, Input } from 'antd';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import { register as registerAction } from '../actions/user';

class RegisterCard extends PureComponent {
    constructor(props) {
        super(props);
        this.handleRegistration = this.handleRegistration.bind(this);
    }

    handleRegistration(e) {
        e.preventDefault();

        const {
            register,
            form: { validateFields },
        } = this.props;

        validateFields((err, values) => {
            if (!err) {
                register(values);
            }
        });
    }

    render() {
        const { intl, form: { getFieldDecorator } } = this.props;

        return (
            <Card className="sign-in-card">
                <h4><FormattedMessage id="login.register" /></h4>
                <br />
                <Form onSubmit={this.handleRegistration}>
                    <Form.Item>
                        {getFieldDecorator('lastName', {
                            rules: [{
                                required: true,
                                message: <FormattedMessage
                                    id="login.lastName.required"
                                />,
                            }],
                        })(
                            <Input
                                placeholder={intl.formatMessage({ id: 'login.lastName' })}
                            />,
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('firstName', {
                            rules: [{
                                required: true,
                                message: <FormattedMessage
                                    id="login.firstName.required"
                                />,
                            }],
                        })(
                            <Input
                                placeholder={intl.formatMessage({ id: 'login.firstName' })}
                            />,
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('email', {
                            rules: [{
                                required: true,
                                message: <FormattedMessage
                                    id="login.email.required"
                                />,
                            }],
                        })(
                            <Input
                                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                placeholder={intl.formatMessage({ id: 'login.email' })}
                            />,
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('password', {
                            rules: [{
                                required: true,
                                message: <FormattedMessage
                                    id="login.password.required"
                                />,
                            }],
                        })(
                            <Input
                                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                type="password"
                                placeholder={intl.formatMessage({ id: 'login.password' })}
                            />,
                        )}
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="sign-in-card-button">
                            <FormattedMessage
                                id="login.button.register"
                            />
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        );
    }
}

RegisterCard.propTypes = {
    intl: intlShape.isRequired,
    form: PropTypes.shape({
        getFieldDecorator: PropTypes.func.isRequired,
    }).isRequired,
    register: PropTypes.func.isRequired,
};

const RegisterCardForm = Form.create({ name: 'normal_register' })(RegisterCard);

export default connect(null, { register: registerAction })(injectIntl(RegisterCardForm));
