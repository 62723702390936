import React, { Component } from 'react';

import EditUserModalContent from './EditUserModalContent';

class EditUserModal extends Component {
    constructor(props) {
        super(props);

        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.getAuthorizedServices = this.getAuthorizedServices.bind(this);

        this.state = {
            visible: false,
            user: null,
            loading: false,
            authorizedServices: [],
        };
    }

    getAuthorizedServices(user) {
        const { id } = user;

        const headers = {
            'Content-type': 'application/json',
            "x-access-token": sessionStorage.getItem("JWT")
        };
        const options = { headers, method: 'GET' };

        fetch(`${process.env.REACT_APP_API_URL}/services/authorized/${id}`, options)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                return response.error;
            })
            .then((data) => {
                if (data) {
                    this.setState({
                        loading: false,
                        authorizedServices: data,
                    });
                }
            });
    }

    show(user) {
        this.setState({
            loading: true,
            visible: true,
            user,
        });

        this.getAuthorizedServices(user);
    }

    hide() {
        this.setState({
            visible: false,
        });
    }

    render() {
        const { visible, user, loading, authorizedServices } = this.state;

        return (
            <>
                {user && (
                    <EditUserModalContent
                        hide={this.hide}
                        visible={visible}
                        user={user}
                        loading={loading}
                        authorizedServices={authorizedServices}
                    />
                )}
            </>
        );
    }
}

export default EditUserModal;
