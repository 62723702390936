import { LOCALE_SET } from '../actions/app';

const defaultState = { locale: 'fr' };

const appReducer = (state = defaultState, { type, ...payload }) => {
    switch (type) {
        case LOCALE_SET:
            return { locale: payload.locale };
        default:
            return state;
    }
};

export default appReducer;
