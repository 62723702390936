import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import './ServiceDoc.css';

const ServiceDoc = ({ name, method, route, desc, example, token }) => {
    const host = `https://api.roote.ai/`;

    return (
        <div className="service-doc">
            <h3 className="service-doc-title">{name}</h3>
            <div className="service-doc-desc-container">
                <p className="service-doc-route">{`${method} : ${host}${route}`}</p>
                <p className="service-doc-desc">
                    {`${desc} : `}
                    <a href={`${host}${example}?token=${token}`} target="_blank" rel="noopener noreferrer">
                        <span className="service-doc-example">{example}</span>
                    </a>
                </p>
            </div>
        </div>
    );
};

ServiceDoc.propTypes = {
    name: PropTypes.string.isRequired,
    method: PropTypes.string.isRequired,
    route: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
    example: PropTypes.string.isRequired,
    token: PropTypes.string,
};

ServiceDoc.defaultProps = {
    token: null,
};

const mapStateToProps = ({ user: { servicesApiTokens } }) => (
    { token: servicesApiTokens.length ? servicesApiTokens[0].token : null }
);

export default connect(mapStateToProps)(ServiceDoc);
