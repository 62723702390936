import { SET_AUTHORIZED_SERVICES, SET_ALL_SERVICES } from '../actions/services';

const defaultState = {
    authorizedServices: [],
    allServices: [],
};

const servicesReducer = (state = defaultState, { type, ...payload }) => {
    switch (type) {
        case SET_AUTHORIZED_SERVICES:
            return { ...state, authorizedServices: payload.authorizedServices };
        case SET_ALL_SERVICES:
            return { ...state, allServices: payload.allServices };
        default:
            return state;
    }
};

export default servicesReducer;
