import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { Row, Modal, Form, Input, Switch, InputNumber, Checkbox, Spin } from 'antd';

import { getAllServices as getAllServicesAction } from '../actions/services';
import { getUsers as getUsersAction } from '../actions/users';

class EditUserModalContent extends Component {
    constructor(props) {
        super(props);

        this.handleOk = this.handleOk.bind(this);

        this.state = {
            confirmLoading: false,
        };
    }

    componentDidMount() {
        const { getAllServices } = this.props;

        getAllServices();
    }

    handleOk() {
        const {
            getUsers,
            hide,
            user: { id },
            form: { validateFields },
        } = this.props;

        this.setState({
            confirmLoading: true,
        });

        validateFields((err, values) => {
            if (!err) {
                const body = JSON.stringify(values);
                const headers = {
                    'Content-type': 'application/json',
                    "x-access-token": sessionStorage.getItem("JWT")
                };
                const options = { body, headers, method: 'PATCH' };

                fetch(`${process.env.REACT_APP_API_URL}/user/${id}`, options)
                    .then(() => {
                        getUsers();

                        this.setState({
                            confirmLoading: false,
                        });
                        hide();
                    });
            }
        });
    }

    render() {
        const { confirmLoading } = this.state;
        const {
            intl,
            allServices,
            form: { getFieldDecorator },
            hide,
            visible,
            user,
            loading,
            authorizedServices,
        } = this.props;

        const formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 14 },
        };

        return (
            <Modal
                title={intl.formatMessage(
                    { id: 'users.modal.title' },
                    { firstName: user.firstName, lastName: user.lastName },
                )}
                visible={visible}
                onOk={this.handleOk}
                confirmLoading={confirmLoading}
                onCancel={hide}
            >
                {!loading && allServices.length ? (
                    <Form {...formItemLayout} layout="horizontal">
                        <Form.Item label={intl.formatMessage({ id: 'users.modal.email' })}>
                            {getFieldDecorator('email', {
                                initialValue: user.email,
                                rules: [{
                                    required: true,
                                    message: <FormattedMessage id="users.modal.fieldsRequired" />,
                                }],
                            })(
                                <Input
                                    placeholder={intl.formatMessage({ id: 'users.modal.email' })}
                                />,
                            )}
                        </Form.Item>
                        <Form.Item label={intl.formatMessage({ id: 'users.modal.credits' })}>
                            {getFieldDecorator('credits', {
                                initialValue: user.credits,
                                rules: [{
                                    required: true,
                                    message: <FormattedMessage id="users.modal.fieldsRequired" />,
                                }],
                            })(
                                <InputNumber
                                    placeholder={intl.formatMessage({ id: 'users.modal.credits' })}
                                />,
                            )}
                        </Form.Item>
                        <Form.Item label={intl.formatMessage({ id: 'users.modal.admin' })}>
                            {getFieldDecorator('admin', {
                                valuePropName: 'checked',
                                initialValue: 'admin' === user.role,
                                rules: [{
                                    required: true,
                                    message: <FormattedMessage id="users.modal.fieldsRequired" />,
                                }],
                            })(
                                <Switch />,
                            )}
                        </Form.Item>
                        <Form.Item label={intl.formatMessage({ id: 'users.modal.services' })}>
                            {getFieldDecorator('authorizedServices', {
                                initialValue: authorizedServices.map((service) => (
                                    service.id
                                )),
                            })(
                                <Checkbox.Group>
                                    {allServices.map((service) => (
                                        <Row key={service.id}>
                                            <Checkbox value={service.id}>
                                                {service.name}
                                            </Checkbox>
                                        </Row>
                                    ))}
                                </Checkbox.Group>,
                            )}
                        </Form.Item>
                    </Form>
                ) : (
                    <Spin />
                )}
            </Modal>
        );
    }
}

EditUserModalContent.propTypes = {
    intl: intlShape.isRequired,
    getAllServices: PropTypes.func.isRequired,
    getUsers: PropTypes.func.isRequired,
    allServices: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired,
    hide: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
    user: PropTypes.shape({
        id: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        role: PropTypes.string.isRequired,
        credits: PropTypes.number.isRequired,
    }).isRequired,
    loading: PropTypes.bool.isRequired,
    authorizedServices: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired,
    form: PropTypes.shape({
        getFieldDecorator: PropTypes.func.isRequired,
    }).isRequired,
};

const EditUserModalContentForm = Form.create({ name: 'edit_user' })(EditUserModalContent);

const mapStateToProps = ({ services: { allServices } }) => ({ allServices });
const mapDispatchToProps = { getAllServices: getAllServicesAction, getUsers: getUsersAction };

export default compose(
    injectIntl,
    connect(mapStateToProps, mapDispatchToProps),
)(EditUserModalContentForm);
