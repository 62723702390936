import { SET_PACKS } from '../actions/packs';

const defaultState = {
    packs: [],
};

const packsReducer = (state = defaultState, { type, ...payload }) => {
    switch (type) {
        case SET_PACKS:
            return { ...state, packs: payload.packs };
        default:
            return state;
    }
};

export default packsReducer;
