import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Layout, Menu, Icon, Alert } from 'antd';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import { resetUser } from '../actions/user';

import logo from '../assets/ROOTE_1.svg';
import './PageLayout.css';

const { Item: MenuItem } = Menu;
const { Header, Content, Sider } = Layout;

class PageLayout extends Component {
    constructor(props) {
        super(props);
        this.onCollapse = this.onCollapse.bind(this);
        this.onLogout = this.onLogout.bind(this);
        this.state = {
            collapsed: false,
        };
    }

    onCollapse(collapsed) {
        this.setState({ collapsed });
    }

    onLogout() {
        const { logout } = this.props;

        logout();
    }

    render() {
        const { collapsed } = this.state;
        const { intl, children, role, isActive, pageKey } = this.props;

        return (
            <Layout className="page-layout">
                <Header className="header">
                    <img className="header-logo" alt="logo" src={logo} />
                    <Menu
                        className="menu-header"
                        theme="dark"
                        mode="horizontal"
                    >
                        <MenuItem key="1" onClick={this.onLogout}>
                            <Icon type="poweroff" />
                        </MenuItem>
                    </Menu>
                </Header>
                <Sider
                    className="menu-sider"
                    collapsible
                    collapsed={collapsed}
                    onCollapse={this.onCollapse}
                >
                    <Menu theme="dark" selectedKeys={[pageKey]} defaultSelectedKeys={['account']} mode="inline">
                        <MenuItem key="account">
                            <NavLink to="/home">
                                <Icon type="user" />
                                <FormattedMessage
                                    id="menu.account"
                                />
                            </NavLink>
                        </MenuItem>
                        <MenuItem key="services">
                            <NavLink to="/services">
                                <Icon type="desktop" />
                                <FormattedMessage
                                    id="menu.services"
                                />
                            </NavLink>
                        </MenuItem>
                        {'admin' === role ? (
                            <MenuItem key="users">
                                <NavLink to="/users">
                                    <Icon type="setting" />
                                    <FormattedMessage
                                        id="menu.users"
                                    />
                                </NavLink>
                            </MenuItem>
                        ) : null }
                    </Menu>
                </Sider>
                <Layout className={collapsed ? 'layout-content-extended' : 'layout-content'}>
                    <Content className="content">
                        {!isActive && (
                            <div className="activation-alert">
                                <Alert
                                    message={intl.formatMessage({ id: 'alert.activation' })}
                                    type="warning"
                                />
                            </div>
                        )}
                        {children}
                    </Content>
                </Layout>
            </Layout>
        );
    }
}

PageLayout.propTypes = {
    intl: intlShape.isRequired,
    role: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    logout: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    pageKey: PropTypes.string.isRequired,
};

const mapStateToProps = ({ user: { role, isActive } }) => ({ role, isActive });

export default compose(
    injectIntl,
    connect(mapStateToProps, { logout: resetUser }),
)(PageLayout);
