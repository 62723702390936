import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import app from './app';
import user from './user';
import services from './services';
import users from './users';
import packs from './packs';

const userPersistConfig = {
    key: 'auth',
    storage,
    blacklist: ['message'],
};

const rootReducer = combineReducers({
    app,
    user: persistReducer(userPersistConfig, user),
    services,
    users,
    packs,
});

export default rootReducer;
