import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';

import PageLayout from '../Layout/PageLayout';
import ServiceDoc from '../components/ServiceDoc';

import { getAuthorizedServices as getAuthorizedServicesAction } from '../actions/services';

class Services extends Component {
    componentDidMount() {
        const { getAuthorizedServices } = this.props;

        getAuthorizedServices();

        document.title = 'ROOTE Dashboard';
    }

    render() {
        const { intl, authorizedServices } = this.props;

        return (
            <PageLayout pageKey="services">
                <h2>
                    {intl.formatMessage({ id: 'services.title' })} ➡️
                    <a href="https://docs.roote.ai" target="_Blank">Voir la documentation de l'API.</a>
                </h2>
                {authorizedServices.map((service) => (
                    <ServiceDoc key={service.id} {...service} />
                ))}
            </PageLayout>
        );
    }
}

Services.propTypes = {
    intl: intlShape.isRequired,
    getAuthorizedServices: PropTypes.func.isRequired,
    authorizedServices: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        method: PropTypes.string.isRequired,
        route: PropTypes.string.isRequired,
        desc: PropTypes.string.isRequired,
        example: PropTypes.string.isRequired,
    })).isRequired,
};

const mapStateToProps = ({ services: { authorizedServices } }) => ({ authorizedServices });
const mapDispatchToProps = { getAuthorizedServices: getAuthorizedServicesAction };

export default compose(
    injectIntl,
    connect(mapStateToProps, mapDispatchToProps),
)(Services);
