import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Modal, Button } from 'antd';
import { Elements } from 'react-stripe-elements';

import PageLayout from '../Layout/PageLayout';
import CheckoutForm from '../components/CheckoutForm';

import { generateServicesApiToken as generateServicesApiTokenAction, getUser as getUserAction } from '../actions/user';
import { getPacks as getPacksAction } from '../actions/packs';

import './Home.css';

const { confirm, success, error } = Modal;

class Home extends Component {
    constructor(props) {
        super(props);

        this.onGenerateTokenClick = this.onGenerateTokenClick.bind(this);
        this.handlePaymentSuccess = this.handlePaymentSuccess.bind(this);
        this.handlePaymentError = this.handlePaymentError.bind(this);

        this.state = {
            packToBuy: null,
        };
    }

    componentDidMount() {
        const { getUser, getPacks } = this.props;

        document.title = 'ROOTE Dashboard';

        getUser();
        getPacks();
    }

    onGenerateTokenClick() {
        const { intl, generateServicesApiToken, user } = this.props;
        const { servicesApiTokens } = user;

        if (servicesApiTokens.length) {
            confirm({
                title: intl.formatMessage({ id: 'user.generateToken.title' }),
                content: intl.formatMessage({ id: 'user.generateToken.content' }),
                onOk() {
                    generateServicesApiToken();
                },
                onCancel() {},
            });
        } else {
            generateServicesApiToken();
        }
    }

    handlePaymentSuccess(pack) {
        const { intl, getUser } = this.props;

        getUser();

        success({
            title: intl.formatMessage({ id: 'user.paymentSuccess' }),
            content: intl.formatMessage({ id: 'user.creditsAdded' }, { credits: pack.credits }),
        });

        this.setState({ packToBuy: null });
    }

    handlePaymentError(pack) {
        const { intl } = this.props;

        error({
            title: intl.formatMessage({ id: 'user.paymentFailed' }),
            content: intl.formatMessage({ id: 'user.creditsNotAdded' }, { credits: pack.credits }),
        });
    }

    render() {
        const { packToBuy } = this.state;
        const { user, packs } = this.props;
        const { firstName, lastName, email, servicesApiTokens, totalCredits, credits, callNumber, role } = user;

        return (
            <PageLayout pageKey="account">
                <h2>
                    {<FormattedMessage
                        id="user.title"
                    />}
                </h2>
                <div className="account-container">
                    <div className="user-info">
                        <div className="user-info-line">
                            <h5 className="user-info-title">
                                <FormattedMessage
                                    id="user.firstName"
                                />
                            </h5>
                            <p className="user-info-content">{firstName}</p>
                        </div>
                        <div className="user-info-divider" />
                        <div className="user-info-line">
                            <h5 className="user-info-title">
                                <FormattedMessage
                                    id="user.lastName"
                                />
                            </h5>
                            <p className="user-info-content">{lastName}</p>
                        </div>
                        <div className="user-info-divider" />
                        <div className="user-info-line">
                            <h5 className="user-info-title">
                                <FormattedMessage
                                    id="user.email"
                                />
                            </h5>
                            <p className="user-info-content">{email}</p>
                        </div>
                        <div className="user-info-divider" />
                        <div className="user-info-line">
                            <h5 className="user-info-title">
                                <FormattedMessage
                                    id="user.token"
                                />
                            </h5>
                            <p className="user-info-content">
                                {servicesApiTokens.length ? servicesApiTokens[0].token : null}
                            </p>
                        </div>
                        <Button
                            className="user-info-generate-token"
                            type="primary"
                            onClick={this.onGenerateTokenClick}
                        >
                            <FormattedMessage
                                id={servicesApiTokens.length
                                    ? 'user.button.regenerateToken'
                                    : 'user.button.generateToken'}
                            />
                        </Button>
                        <div className="user-info-divider" />
                        {'admin' === role ? (
                            <div className="user-info-line">
                                <h5 className="user-info-title">
                                    <FormattedMessage
                                        id="user.callNumber"
                                    />
                                </h5>
                                <p className="user-info-content">
                                    {callNumber}
                                </p>
                            </div>
                        ) : (
                            <div className="user-info-line">
                                <h5 className="user-info-title">
                                    <FormattedMessage
                                        id="user.usedCredit"
                                    />
                                </h5>
                                <p className="user-info-content">
                                    {`${totalCredits - credits} / ${totalCredits}`}
                                </p>
                            </div>
                        )}
                    </div>
                    <div className="add-credits-container">
                        <div className="add-credits-buttons">
                            {packs.map((pack) => (
                                <Button
                                    key={pack.id}
                                    className="add-credits-button"
                                    type="primary"
                                    onClick={() => this.setState({ packToBuy: pack })}
                                >
                                    <FormattedMessage
                                        id="user.button.addCredit"
                                        values={{ credits: pack.credits, price: pack.price / 100 }}
                                    />
                                </Button>
                            ))}
                        </div>
                        {packToBuy !== null && (
                            <Elements>
                                <CheckoutForm
                                    pack={packToBuy}
                                    onPaymentSuccess={this.handlePaymentSuccess}
                                    onPaymentError={this.handlePaymentError}
                                />
                            </Elements>
                        )}
                    </div>
                </div>
            </PageLayout>
        );
    }
}

Home.propTypes = {
    intl: intlShape.isRequired,
    getUser: PropTypes.func.isRequired,
    generateServicesApiToken: PropTypes.func.isRequired,
    getPacks: PropTypes.func.isRequired,
    user: PropTypes.shape({
        id: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        role: PropTypes.string.isRequired,
        callNumber: PropTypes.number.isRequired,
        totalCredits: PropTypes.number.isRequired,
        credits: PropTypes.number.isRequired,
        isActive: PropTypes.bool.isRequired,
        servicesApiTokens: PropTypes.arrayOf(PropTypes.shape({
            token: PropTypes.string.isRequired,
        })),
    }),
    packs: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        credits: PropTypes.number.isRequired,
    })),
};

const mapStateToProps = ({ user, packs: { packs } }) => ({ user, packs });
const mapDispatchToProps = {
    getUser: getUserAction,
    generateServicesApiToken: generateServicesApiTokenAction,
    getPacks: getPacksAction,
};

export default compose(
    injectIntl,
    connect(mapStateToProps, mapDispatchToProps),
)(Home);
