import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Card, Form, Icon, Input } from 'antd';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';

import { login as loginAction } from '../actions/user';

class LoginCard extends PureComponent {
    constructor(props) {
        super(props);
        this.handleLogin = this.handleLogin.bind(this);
    }

    handleLogin(e) {
        e.preventDefault();

        const {
            login,
            form: { validateFields },
        } = this.props;

        validateFields((err, values) => {
            if (!err) {
                login(values);
            }
        });
    }

    render() {
        const { intl, form: { getFieldDecorator } } = this.props;

        return (
            <Card className="sign-in-card">
                <h4><FormattedMessage id="login.login" /></h4>
                <br />
                <Form onSubmit={this.handleLogin}>
                    <Form.Item>
                        {getFieldDecorator('email', {
                            rules: [{
                                required: true,
                                message: <FormattedMessage
                                    id="login.email.required"
                                />,
                            }],
                        })(
                            <Input
                                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                placeholder={intl.formatMessage({ id: 'login.email' })}
                            />,
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('password', {
                            rules: [{
                                required: true,
                                message: <FormattedMessage
                                    id="login.password.required"
                                />,
                            }],
                        })(
                            <Input
                                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                type="password"
                                placeholder={intl.formatMessage({ id: 'login.password' })}
                            />,
                        )}
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="sign-in-card-button">
                            <FormattedMessage
                                id="login.button.login"
                            />
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        );
    }
}

LoginCard.propTypes = {
    intl: intlShape.isRequired,
    login: PropTypes.func.isRequired,
    form: PropTypes.shape({
        getFieldDecorator: PropTypes.func.isRequired,
    }).isRequired,
};

const LoginCardForm = Form.create({ name: 'normal_login' })(LoginCard);

export default compose(
    injectIntl,
    connect(null, { login: loginAction }),
)(LoginCardForm);
