export const SET_USERS = 'SET_USERS';

const setUsers = (users) => ({
    type: SET_USERS,
    users,
});

export const getUsers = () => (dispatch) => {
    const headers = {
        'Content-type': 'application/json',
        "x-access-token": sessionStorage.getItem("JWT")
    };
    const options = { headers, method: 'GET' };

    fetch(`${process.env.REACT_APP_API_URL}/users/`, options)
        .then((response) => {
            if (response.ok) {
                return response.json();
            }

            return response.error;
        })
        .then((data) => {
            if (data) {
                dispatch(setUsers(data));
            }
        });
};
