import { SET_USER, RESET_USER, SET_USER_SERVICES_API_TOKENS, SET_USER_MESSAGE } from '../actions/user';

const defaultState = {
    loggedIn: false,
    message: null,
    id: null,
    email: null,
    credits: null,
    totalCredits: null,
    callNumber: null,
    role: 'user',
    isActive: false,
    lastName: null,
    firstName: null,
    servicesApiTokens: [],
};

const userReducer = (state = defaultState, { type, ...payload }) => {
    const newServicesApiTokens = [];

    switch (type) {
        case SET_USER:
            return {
                ...state,
                loggedIn: true,
                message: null,
                id: payload.id,
                email: payload.email,
                credits: payload.credits,
                totalCredits: payload.totalCredits,
                callNumber: payload.callNumber,
                role: payload.role,
                isActive: payload.isActive,
                lastName: payload.lastName,
                firstName: payload.firstName,
                servicesApiTokens: payload.servicesApiTokens,
            };
        case RESET_USER:
            return defaultState;
        case SET_USER_SERVICES_API_TOKENS:
            newServicesApiTokens.push(payload.servicesApiTokens);

            return { ...state, servicesApiTokens: newServicesApiTokens };
        case SET_USER_MESSAGE:
            return { ...state, message: payload.message };
        default:
            return state;
    }
};

export default userReducer;
