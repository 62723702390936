export const SET_PACKS = 'SET_PACKS';

const setPacks = (packs) => ({
    type: SET_PACKS,
    packs,
});

export const getPacks = () => (dispatch) => {
    const headers = {
        'Content-type': 'application/json',
        "x-access-token": sessionStorage.getItem("JWT")
    };
    const options = { headers, method: 'GET' };

    fetch(`${process.env.REACT_APP_API_URL}/packs`, options)
        .then((response) => {
            if (response.ok) {
                return response.json();
            }

            return response.error;
        })
        .then((data) => {
            if (data) {
                dispatch(setPacks(data));
            }
        });
};
