import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { resetUser } from '../actions/user';

const mapStateToProps = ({ user: { loggedIn } }) => ({ loggedIn });

const requireAuth = (ComposedComponent) => {
    class auth extends Component {
        constructor(props) {
            super(props);

            this.state = {
                loading: true,
                redirect: false,
            };
        }

        componentDidMount() {
            this.checkLoggedIn();
        }

        checkLoggedIn() {
            fetch(`${process.env.REACT_APP_API_URL}/auth/checkToken`, {
                headers: {"x-access-token": sessionStorage.getItem("JWT")}
            })
                .then((res) => {
                    if (200 === res.status) {
                        this.setState({ loading: false });
                    } else {
                        throw new Error(res.error);
                    }
                })
                .catch((err) => {
                    const { logout } = this.props;

                    console.error(err);
                    this.setState({ loading: false, redirect: true });
                    logout();
                });
        }

        render() {
            const { loading, redirect } = this.state;
            const { loggedIn } = this.props;

            if (loading) {
                return null;
            }
            if (!loggedIn || redirect) {
                return <Redirect to="/" />;
            }

            return (
                <React.Fragment>
                    <ComposedComponent {...this.props} />
                </React.Fragment>
            );
        }
    }

    auth.propTypes = {
        logout: PropTypes.func.isRequired,
        loggedIn: PropTypes.bool.isRequired,
    };

    return connect(mapStateToProps, { logout: resetUser })(auth);
};

export default requireAuth;
