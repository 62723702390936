import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Login from './routes/Login';
import Home from './routes/Home';
import Services from './routes/Services';
import UserManagements from './routes/Users';

import dontRequireAuth from './hoc/dontRequireAuth';
import requireAuth from './hoc/requireAuth';

const Routes = () => (
    <Switch>
        <Route exact path="/" component={dontRequireAuth(Login)} />
        <Route exact path="/home" component={requireAuth(Home)} />
        <Route exact path="/services" component={requireAuth(Services)} />
        <Route exact path="/users" component={requireAuth(UserManagements)} />
    </Switch>
);

export default Routes;
