// eslint-disable-next-line import/no-anonymous-default-export
export default {
    'login.login': 'Connexion',
    'login.register': 'Inscription',
    'login.failed': 'La connexion a échouée',
    'login.email.required': 'Un email est requis',
    'login.email': 'Email',
    'login.password.required': 'Un mot de passe est requis',
    'login.password': 'Mot de passe',
    'login.button.login': 'Se connecter',
    'login.button.register': 'S\'inscrire',
    'login.lastName': 'Nom',
    'login.lastName.required': 'Un nom est requis',
    'login.firstName': 'Prénom',
    'login.firstName.required': 'Un prénom est requis',
    'login.registration.success': 'Activez votre compte en cliquant sur le lien dans le mail',
    'login.registration.fail': 'Un compte existe déjà avec cette adresse mail',

    'menu.account': 'Compte',
    'menu.services': 'Services',
    'menu.users': 'Utilisateurs',

    'user.title': 'Vos informations utilisateur',
    'user.firstName': 'Prénom',
    'user.lastName': 'Nom',
    'user.email': 'Email',
    'user.token': 'Token actif',
    'user.usedCredit': 'Crédits utilisés',
    'user.callNumber': 'Nombre d\'appels',
    'user.button.generateToken': 'Générer un token',
    'user.button.regenerateToken': 'Regénérer le token',
    'user.generateToken.title': 'Etes vous sûre de vouloir regénérer un token ?',
    'user.generateToken.content': 'Vous n\'avez qu\'un token. Si vous en regénérez un, vous devrez le changer partout où il est utilisé.',
    'user.button.addCredit': 'Acheter {credits} crédits pour {price}€',
    'user.paymentSuccess': 'Paiement effectué',
    'user.creditsAdded': 'Votre compte a bien été crédité de {credits} crédits',
    'user.paymentFailed': 'Erreur lors du paiment',
    'user.creditsNotAdded': 'Nous n\'avons pas pu créditer votre compte de {credits} crédits',
    'user.pay': 'Paiement',
    'user.checkoutDesc': 'Entrez vos informations de carte bancaire pour valider le paiement de {credits} crédits',
    'user.button.pay': 'Payer',

    'users.table.firstName': 'Prénom',
    'users.table.lastName': 'Nom',
    'users.table.email': 'Email',
    'users.table.callNumber': 'Nombre d\'appels',
    'users.table.servicesNumber': 'Nombre de services',
    'users.table.search': 'Rechercher',
    'users.table.reset': 'Réinitialiser',
    'users.modal.title': 'Éditer {firstName} {lastName}',
    'users.modal.email': 'Email',
    'users.modal.credits': 'Crédits',
    'users.modal.admin': 'Admin',
    'users.modal.services': 'Services',
    'users.modal.fieldsRequired': 'Toutes les valeurs de ce formulaire sont requires',

    'services.title': 'Services disponibles',

    'alert.activation': 'Votre compte n\'est pas actif. Veuillez cliquer sur le lien d\'activation que vous avez reçu dans vos mails si vous souhaitez utiliser nos APIs',
};
