export const SET_USER = 'SET_USER';
export const RESET_USER = 'RESET_USER';
export const SET_USER_SERVICES_API_TOKENS = 'SET_USER_SERVICES_API_TOKENS';
export const SET_USER_MESSAGE = 'SET_USER_MESSAGE';

export const setUser = (user) => ({
    type: SET_USER,
    ...user,
});

export const resetUser = () => ({
    type: RESET_USER,
});

export const setUserServicesApiTokens = (servicesApiTokens) => ({
    type: SET_USER_SERVICES_API_TOKENS,
    servicesApiTokens,
});

export const setUserMessage = (message) => ({
    type: SET_USER_MESSAGE,
    message,
});

export const register = (values) => (dispatch) => {
    const body = JSON.stringify(values);
    const headers = {
        'Content-type': 'application/json',
    };
    const options = { body, headers, method: 'POST' };

    fetch(`${process.env.REACT_APP_API_URL}/auth/register`, options)
        .then((response) => response.json())
        .then(({ created }) => {
            if (created) {
                dispatch(setUserMessage({
                    error: false,
                    type: 'ACCOUNT_CREATED',
                }));
            } else {
                dispatch(setUserMessage({
                    error: true,
                    type: 'CANT_CREATE_ACCOUNT',
                }));
            }
        });
};

export const login = (values) => (dispatch) => {
    const body = JSON.stringify(values);
    const headers = {
        'Content-type': 'application/json',
    };
    const options = { body, headers, method: 'POST'};

    fetch(`${process.env.REACT_APP_API_URL}/auth/login`, options)
        .then((response) => {
            if (response.ok) {
                return response.json();
            }

            return response.error;
        })
        .then((data) => {
            sessionStorage.setItem('JWT', data.token);

            if (data) {
                dispatch(setUser(data));
            } else {
                dispatch(setUserMessage({
                    error: true,
                    type: 'CANT_LOGIN',
                }));
            }
        });
};

export const generateServicesApiToken = () => (dispatch) => {
    const headers = {
        'Content-type': 'application/json',
        "x-access-token": sessionStorage.getItem("JWT")
    };
    const options = { headers, method: 'POST' };

    fetch(`${process.env.REACT_APP_API_URL}/servicesApiToken/`, options)
        .then((response) => {
            if (response.ok) {
                return response.json();
            }

            return response.error;
        })
        .then((data) => {
            if (data) {
                dispatch(setUserServicesApiTokens(data));
            }
        });
};

export const getUser = () => (dispatch, getState) => {
    const headers = {
        'Content-type': 'application/json',
        "x-access-token": sessionStorage.getItem("JWT")
    };
    const options = { headers, method: 'GET' };

    const { user: { id } } = getState();

    fetch(`${process.env.REACT_APP_API_URL}/user/${id}`, options)
        .then((response) => {
            if (response.ok) {
                return response.json();
            }

            return response.error;
        })
        .then((data) => {
            if (data) {
                dispatch(setUser(data));
            }
        });
};
